// Here you can add other styles
@import "~dropzone/dist/basic.css";
@import "~dropzone/dist/dropzone.css";
@import "~react-dropzone-component/styles/filepicker.css";
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "~react-confirm-alert/src/react-confirm-alert.css";

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 57px;
  z-index: 9;
}

.wrapper-class {
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
}

.editor-class {
  padding: 10px;
}

.toolbar-class {
  background-color: white;
}

.save-btn {
  border-radius: 100%;
  height: 80px;
  width: 80px;
  float: right;
}

.card {
  &.bg-primary {
    .card-title {
      color: white;
    }
  }
}

.upload-media {
  background: none;
  width: 100%;
  padding: 40px 10px;
  border: 1px dashed #888;
  color: #888;
  text-align: center;
}

.nestable-list.nestable-group {
  li {
    margin: 0;
    padding: 0.75rem;
    border: 1px solid #d8dbe0;

    &:nth-of-type(odd) {
      background: rgba(0, 0, 21, 0.05);
    }
  }
}

.clickable {
  cursor: pointer;
}

.close-button:hover {
  background-color: red !important;
}

.close-button {
  margin: 2px;
  padding: 0;
  display: flex;
  /* right:0px; */
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 20px;
  background-color: white !important;
  /* height: 20px; */
}

.media-card {
  padding-top: 100%;

  & .card-img {
    position: absolute;
    top: 0;
    z-index: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  & .card-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    background: white;
  }

  & .media-btn-group {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.tox {
  .tox-editor-header {
    z-index: 0 !important;
  }
}

.error {
  color: red;
}

.image-with-remove-icon {
  border: 1px solid #000;
  margin: 5px;
  position: relative;

  &::after {
    display: none;
    content: "x";
    position: absolute;
    height: 10px;
    width: 10px;
    line-height: 10px;
    font-size: 10px;
    color: #fff;
    background-color: red;
    top: -2px;
    right: -2px;
    border-radius: 5px;
  }

  &:hover {
    &::after {
      display: block;
    }
  }
}